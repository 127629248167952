import React from "react";
import { Link } from "gatsby";

export const Footer = () => {
  return (
    <footer className="site-footer">
      <p>
        &copy; {new Date().getFullYear()} Mini Roof Racks &bull;{" "}
        <Link className={"disclaimer-link"} to="/disclaimer">
          Affiliate disclaimer
        </Link>
      </p>
    </footer>
  );
};

export default Footer;
