import React from "react";
import { Link } from "gatsby";
import { MobileView, BrowserView } from "react-device-detect";
import Popup from "reactjs-popup";
import "./menu/Menu.css";
import { Menu, BurgerIcon } from "./menu/Menu";

const contentStyle = {
  background: "rgba(255,255,255,0",
  width: "80%",
  border: "none",
};

const navigation = (props) => {
  return (
    <nav className="navigation">
      <BrowserView>
        <Link to="/contact">Contact</Link>
      </BrowserView>
      <MobileView>
        <Popup
          modal
          overlayStyle={{ background: "rgba(255,255,255,0.98" }}
          contentStyle={contentStyle}
          closeOnDocumentClick={false}
          trigger={(open) => <BurgerIcon open={open} />}
        >
          {(close) => <Menu close={close} categories={props.categories} />}
        </Popup>
      </MobileView>
    </nav>
  );
};

export default navigation;
