import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Navigation from "../components/navigation";
import "prismjs/themes/prism-okaidia.css";
import Footer from "./footer";

export default ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            categories {
              label
              value
            }
          }
        }
      }
    `
  );
  return (
    <div className="site-wrapper">
      <header className="site-header">
        <div className="site-title">
          <Link to="/">
            <span className="highlight-word">Mini</span> Roof Racks
          </Link>
          {/* <Link to="/">{data.site.siteMetadata.title}</Link> */}
        </div>
        <Navigation categories={data.site.siteMetadata.categories} />
      </header>
      {children}
      <Footer />
    </div>
  );
};
