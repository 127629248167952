import React from "react";
import { Link } from "gatsby";

export const Menu = ({ close, categories }) => {
  return (
    <div className="menu">
      <ul>
        <li onClick={close}>
          <Link to="/">HOME</Link>
        </li>
        {categories &&
          categories.map((cat) => {
            return (
              <li onClick={close}>
                <Link to={`/category/${cat.value}`}>
                  {cat.label.toUpperCase()}
                </Link>
              </li>
            );
          })}
        <li onClick={close}>
          <Link to="/contact">CONTACT</Link>
        </li>
      </ul>
    </div>
  );
};

export const BurgerIcon = ({ open, ...props }) => {
  return (
    <div className={open ? "burger-menu open" : "burger-menu"} {...props}>
      <div className="bar1" key="b1" />
      <div className="bar2" key="b2" />
      <div className="bar3" key="b3" />
    </div>
  );
};
